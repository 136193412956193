/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import { cn } from '@/lib/utils';

import { System } from '../icons';

interface NotFoundMessageProps {
  title?: string;
  message?: string;
  outerDivClassName?: string;
  infoIconClassName?: string;
}

export const NotFoundMessage: React.FC<NotFoundMessageProps> = ({
  title,
  message,
  outerDivClassName,
  infoIconClassName,
}) => {
  return (
    <div
      className={cn(
        'flex w-full flex-col items-center justify-center gap-y-2 text-center',
        outerDivClassName || 'my-[200px]'
      )}
    >
      {System.InfoIcon(
        `size-12 text-form-input-disabled opacity-[48%] ${infoIconClassName}`
      )}
      <h3 className="text-h4 font-medium text-body-primary">{title}</h3>
      <p className="text-form-input-text text-form-input-disabled">{message}</p>
    </div>
  );
};
