/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { createContext } from 'react';

const AuthContext = createContext<any>(null);
const ToggleContext = createContext<any>(null);
const TourContext = createContext<any>(null);
const PromptsContext = createContext<any>(null);
const TracesContext = createContext<any>(null);
const AppDetailsContext = createContext<any>(null);
const KPIContext = createContext<any>(null);
const ProvidersContext = createContext<any>(null);
const ServicesContext = createContext<any>(null);
const ComponentsContext = createContext<any>(null);

export {
  AuthContext,
  ToggleContext,
  TourContext,
  PromptsContext,
  TracesContext,
  AppDetailsContext,
  KPIContext,
  ProvidersContext,
  ServicesContext,
  ComponentsContext,
};
