/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import { generateCode } from '@/utils/generateCode';
import {
  AppGraph,
  Component,
  ComponentJSON,
  ComponentsData,
  LinkType,
  NodeType,
  Provider,
  SubDataArrayType,
} from 'types/api-data';
import { OptionsType } from 'types/multi-select';
import { IPrompt } from 'types/prompt';
import { ProvidersJSON, SupportedFeature } from 'types/providers';

type IconMapType = { [key: string]: string };

export const iconMap: IconMapType = {
  'workflow.langchain': '/logos/provider/langchain.svg',
  'workflow.llamaindex': '/logos/provider/LlamaSquareBlack.svg',
  'workflow.haystack': '/logos/provider/haystack.svg',
  'workflow.openai': '/logos/provider/openai.svg',
  'workflow.vercel': '/logos/provider/vercel.svg',
  'workflow.gemini': '/logos/service/google-gemini.svg',
  'workflow.teams_ai': '/logos/service/teams_ai.svg',
  'model.llm': '/logos/model/model_llm.svg',
  'model.embedding': '/logos/model/embedding_model.svg',
  'model.generic': '/logos/provider/model-generic.svg',
  'model.openai': '/logos/provider/openai.svg',
  'model.llama': '/logos/model/meta_llama.svg',
  'model.deepseek': '/logos/model/deepseek.svg',
  'model.custom': '/logos/model/model_custom.svg',
  'model.anthropic': '/logos/model/anthropic.svg',
  'model.mistral': '/logos/model/mistral.svg',
  'app_hosting.aws_lambda': '/logos/provider/lambda.svg',
  'app_hosting.github_codespace': '/logos/provider/github-codespace.svg',
  'app_hosting.azure_func': '/logos/service/azure-function.svg',
  'app_hosting.vercel': '/logos/provider/vercel.svg',
  'inference.nvidia_triton': '/logos/service/triton.svg',
  'compute.kubernetes': '/logos/service/kubernetes.svg',
  'inference.azure_openai': '/logos/service/azure-openai.svg',
  'inference.openai': '/logos/provider/openai.svg',
  'inference.openai.model': '/logos/provider/openai.svg',
  'inference.aws_bedrock': '/logos/service/bedrock.svg',
  'inference.aws_sagemaker': '/logos/service/sagemaker.svg',
  'inference.gemini': '/logos/service/google-gemini.svg',
  'app_hosting.azure_mlw': '/logos/service/azure-ml.svg',
  'vectorstore.chroma': '/logos/service/chroma.svg',
  'vectorstore.opensearchvectorsearch': '/logos/service/aws-opensearch.svg',
  'vectorstore.milvus': '/logos/service/milvus.svg',
  'inference.hugging_face': '/logos/provider/hugging-face.svg',
  'vectorstore.pinecone': '/logos/service/pinecone.svg',
  'vectorstore.aws_es': '/logos/service/aws-opensearch.svg',
  azure: '/logos/provider/microsoft-azure.svg',
  aws: '/logos/provider/aws.svg',
  github: '/logos/provider/github.svg',
  gcp: '/logos/provider/gcp.svg',
  openai: '/logos/provider/openai.svg',
  nvidia: '/logos/provider/nvidia.svg',
  hugging_face: '/logos/provider/hugging-face.svg',
  vercel: '/logos/provider/vercel.svg',
  default: '/logos/provider/not-available.svg',
};

export const multiSelectDataTransform = (
  data: Component[] | Provider[] | undefined,
  model?: boolean
) => {
  const subData: OptionsType = [];
  let id = 0;

  data?.forEach((entity: any) => {
    if (!model || !entity?.properties?.ml_model) {
      if (!model) {
        const name = entity?.component_name || entity?.provider_name;
        const label = entity?.display_name;
        subData.push({ id: id++, value: name, label });
      }
      return; // Just skip this iteration
    }

    const name = entity?.properties?.ml_model;
    const label = entity?.properties?.ml_model;
    subData.push({ id: id++, value: name, label });
  });

  return subData;
};

export const removeFilterDuplicates = (arr: OptionsType) => {
  const seen = new Set();
  return arr.filter((item) => {
    const duplicate = seen.has(item.value);
    seen.add(item.value);
    return !duplicate;
  });
};

export const graphDataTransform = (
  data: AppGraph
): {
  node: NodeType[];
  link: LinkType[];
} => {
  const filterLogical = data.components.filter(
    (item) => item.domain === 'logical'
  );

  let key = 0;
  const subDataArray: SubDataArrayType[] = [];
  filterLogical.forEach(({ display_name, component_name, type, status }) => {
    subDataArray.push({ key, display_name, component_name, type, status });
    key++;
  });

  key = 0;

  const nodeDataArray: NodeType[] = [];
  const linkDataArray: LinkType[] = [];

  filterLogical.forEach(({ display_name, type }) => {
    nodeDataArray.push({
      key,
      name: display_name,
      source: iconMap[type] ?? iconMap['default'],
    });
    key++;
  });

  key = 0;

  const filterConsumes = data.dependencies.filter(
    (item) => item.dependency_type === 'consumes'
  );

  const keyLookUp = (name: string) => {
    const obj = subDataArray?.filter((item) => item.component_name === name);

    return obj[0]?.key;
  };

  filterConsumes.forEach((dependency) => {
    linkDataArray.push({
      key: key++,
      from: keyLookUp(dependency.source),
      to: keyLookUp(dependency.target),
    });
  });

  return { node: nodeDataArray, link: linkDataArray };
};

export const formatDuration = (durationMs: number, short?: boolean) => {
  const milliseconds = durationMs % 1000;
  const seconds = Math.floor((durationMs / 1000) % 60);
  const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
  const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
  const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));

  let result = '';

  if (short) {
    if (days > 0) {
      result += `${days}d `;
    }
    if (hours > 0) {
      result += `${hours}h `;
    }
    if (minutes > 0) {
      result += `${minutes}m `;
    }
    if (seconds > 0 || durationMs < 1000) {
      result += `${seconds}s `;
    }
    if (milliseconds > 0 && durationMs < 1000) {
      result += `${milliseconds}ms `;
    }
  } else {
    if (days > 0) {
      result += `${days} day${days > 1 ? 's' : ''} `;
    }
    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? 's' : ''} `;
    }
    if (minutes > 0) {
      result += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    }
    if (seconds > 0 || durationMs < 1000) {
      result += `${seconds} second${seconds > 1 ? 's' : ''} `;
    }
    if (milliseconds > 0 && durationMs < 1000) {
      result += `${milliseconds} millisecond${milliseconds > 1 ? 's' : ''} `;
    }
  }

  return result.trim();
};

export const formatSecondsAndMilliseconds = (durationMs: number) => {
  const milliseconds = durationMs % 1000;
  const seconds = Math.floor((durationMs / 1000) % 60);

  let result = '';

  if (seconds > 0) {
    result += `${seconds}s `;
  }

  if (milliseconds > 0 || seconds === 0) {
    result += `${milliseconds}ms `;
  }

  return result.trim();
};

export const removeDuplicates = (data: SupportedFeature[]) => {
  const uniqueItems: SupportedFeature[] = [];
  const seen = new Set();

  data.forEach((item) => {
    const identifier = `${item.type}-${item.display_name}`;
    if (!seen.has(identifier)) {
      seen.add(identifier);
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
};

// Function to process providers and create an array of objects with supported features
export const processProviders = (data: ProvidersJSON) => {
  const result: SupportedFeature[] = [];

  // Loop through each provider
  data.providers.forEach((provider) => {
    const features = provider.supported_functions;

    // Add each feature to the result array with provider info
    features.forEach((feature) => {
      result.push(feature);
    });
  });

  return result;
};

// Filter array for Components
// Multi-select array for ComponentJSON
export const createFilterArray = (data: ComponentsData | ComponentJSON[]) => {
  const result: string[] = [];
  data?.map((component) =>
    result.push(component.type.slice(0, component.type.indexOf('.')))
  );

  return Array.from(new Set(result));
};

// Function to create name with a random 6 char suffix
export const createNameWithSuffix = (name: string, charNo = 7) => {
  let baseName = name.trim().toLowerCase().replaceAll(' ', '_');

  const maxBaseNameLength = 64 - charNo;
  if (baseName.length > maxBaseNameLength) {
    baseName = baseName.slice(0, maxBaseNameLength);
  }
  const uniqueCode = generateCode();
  return `${baseName}_${uniqueCode}`;
};

export const formatPromptOutput = (promptOutput: IPrompt) => {
  if (Array.isArray(promptOutput?.output?.response)) {
    return promptOutput?.output?.response[0];
  }
  return promptOutput?.output?.response;
};
